import React from 'react'
import { useSelector } from 'react-redux'
import { Glyphicon } from 'react-bootstrap'
import PropTypes from 'prop-types'

import { DATA_IMPACT_SOURCE, OMNISALES_SOURCE } from 'components/NIQSearchPage/SearchDictionaryFilter'

function NIQDescriptionTitleValue({ descriptionValue, merchantName, rct_code }) {
  const niqDataSource = useSelector(state => state.niqSearch.searchFilters.dataImpactFilter)

  return (
    <div className="description-table-label">
      <span>{descriptionValue}</span>
      <div className="description-table-label-actions" style={{ cursor: 'pointer' }}>
        {niqDataSource === OMNISALES_SOURCE && (
          <a
            href={`http://www.google.com/search?q=${encodeURIComponent(
              `${descriptionValue} + ${merchantName}`
            )}&tbm=isch`}
            target="_blank"
            rel="noopener noreferrer"
            className="action-icon"
          >
            <Glyphicon glyph={'new-window'} />
          </a>
        )}

        {niqDataSource === DATA_IMPACT_SOURCE && (
          <a
            href={`https://www.amazon.com/dp/${rct_code}`}
            target="_blank"
            rel="noopener noreferrer"
            className="action-icon"
          >
            <Glyphicon glyph={'new-window'} />
          </a>
        )}

        <span
          onClick={elem => {
            window.open(
              `http://www.google.com/search?q=${encodeURIComponent(descriptionValue + merchantName)}&tbm=isch`,
              'Google search',
              `resizable,scrollbars,status,height=600,width=600`
            )
          }}
        >
          <Glyphicon glyph={'camera'} className="action-icon" />
        </span>
      </div>
    </div>
  )
}

NIQDescriptionTitleValue.propTypes = {
  descriptionValue: PropTypes.string,
  merchantName: PropTypes.string,
  rct_code: PropTypes.string
}

export default NIQDescriptionTitleValue

import React from 'react'
import PropTypes from 'prop-types'
import { ES_SCORECARD } from 'constants/constants'

const IndexLabel = ({ dataIndex }) => {
  switch (dataIndex) {
    case ES_SCORECARD.nonScorecard:
      return <div className="pull-right index-label index-label-nonscorecard">Non-Scorecard Data</div>
    case ES_SCORECARD.scorecard:
      return <div className="pull-right index-label index-label-scorecard">Scorecard Data</div>
    default:
      return null
  }
}

IndexLabel.propTypes = {
  dataIndex: PropTypes.string
}

export default IndexLabel

// @flow
import React, { Component } from 'react'
import { OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { WidthProvider, Responsive } from 'react-grid-layout'
import { find } from 'lodash'
import moment from 'moment'

import DescriptionTable from './DescriptionTable'
import TableWidget from './table-widget'
import DateRangeFilter from '../DateRangeFilter'
import DataSummary from './data-summary'
import { ES_SCORECARD, default as constants } from '../../../constants/constants'
import { KEY, LAYOUTS, LAYOUT_VERSION_NUMBER, ROW_HEIGHT } from './layouts'
import ResizableGridHelper from '../../../utils/layout-helper'
import IndexLabel from './IndexLabel'
import '../../../styles/widget.scss'

const {
  aggregations: { BRAND, CATEGORY, MERCHANT, DESCRIPTION_FIRST_WORD }
} = constants
const ResponsiveReactGridLayout = WidthProvider(Responsive)
const layoutHelper = new ResizableGridHelper(KEY, LAYOUTS, LAYOUT_VERSION_NUMBER)

class WidgetWrapper extends Component {
  static propTypes = {
    isLayoutLocked: PropTypes.bool,
    handleDateSelection: PropTypes.func,
    rangeStart: PropTypes.string,
    rangeEnd: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    lastLoadDate: PropTypes.object,
    dataIndex: PropTypes.string
  }

  constructor(props) {
    super(props)
    const layouts = layoutHelper.getLayouts()
    this.state = {
      layout: layouts.lg,
      layouts,
      gridWidth: 1200,
      noOfColumns: 12
    }
  }

  onLayoutChange = (layout, layouts) => {
    layoutHelper.setLayouts(layouts)
    this.setState({ layout, layouts })
  }

  onWidthChange = (
    containerWidth: number,
    margin: [number, number],
    cols: number,
    containerPadding: [number, number]
  ) => {
    this.setState({ gridWidth: containerWidth, noOfColumns: cols })
  }

  /**
   * Fetches height of a widgets
   * @memberof WidgetWrapper
   */

  getWidgetHeight = key => {
    try {
      if (!this.state.layout) {
        return null
      }
      const widget = find(this.state.layout, obj => obj.i === key)
      return widget.h * ROW_HEIGHT
    } catch (err) {
      layoutHelper.setLayouts()
    }
  }

  /**
   * Fetches approx width of the widget.
   * May have minor offsets due to margin between widgets.
   * @memberof WidgetWrapper
   */

  getWidgetWidth = key => {
    try {
      if (!this.state.layout) {
        return null
      }
      const widget = this.state.layout.find(obj => obj.i === key)
      return (widget.w * this.state.gridWidth) / this.state.noOfColumns
    } catch (err) {
      layoutHelper.setLayouts()
    }
  }

  renderWidget = (key, aggType, title) => {
    return (
      <div key={key}>
        <TableWidget
          width={this.getWidgetWidth(key)}
          height={this.getWidgetHeight(key)}
          aggType={aggType}
          getTitle={count => `Top ${count || 0} ${title}`}
        />
      </div>
    )
  }

  getLocalTime = value => {
    return value
      ? moment
          .utc(value)
          .local()
          .format(constants.dateFormat.refreshData)
      : '--'
  }

  loadInfoTooltip = () => {
    const { lastLoadDate, dataIndex } = this.props

    let loadDate = null
    if (dataIndex === ES_SCORECARD.scorecard) loadDate = lastLoadDate.searchDataScorecard
    if (dataIndex === ES_SCORECARD.nonScorecard) loadDate = lastLoadDate.searchData

    return (
      <Tooltip id="refresh-load-info">
        {'Data Refresh: '}
        <span>{this.getLocalTime(loadDate)}</span>
      </Tooltip>
    )
  }

  render() {
    const { rangeStart, rangeEnd, from, to, handleDateSelection, isLayoutLocked, lastLoadDate } = this.props
    return (
      <div className="widget-wrapper">
        <div className="widget-filters">
          <div className="widget-filters" style={{ position: 'absolute', display: 'flex', top: -38, right: 70 }}>
            {lastLoadDate && (
              <OverlayTrigger placement="left" overlay={this.loadInfoTooltip()}>
                <Glyphicon style={{ paddingTop: '10px' }} className="pull-right" glyph="info-sign" />
              </OverlayTrigger>
            )}
            <DateRangeFilter
              handleDateSelection={handleDateSelection}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              className="pull-right widget-filters-daterange"
              startDate={from}
              endDate={to}
            />
            <IndexLabel dataIndex={this.props.dataIndex} />
          </div>
        </div>
        <ResponsiveReactGridLayout
          onResizeStop={this.onResizeStop}
          onLayoutChange={this.onLayoutChange}
          onWidthChange={this.onWidthChange}
          layouts={this.state.layouts}
          cols={{ lg: 12, md: 9, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={ROW_HEIGHT}
          width={1200}
          isDraggable={!isLayoutLocked}
          isResizable={!isLayoutLocked}
          className={isLayoutLocked ? 'locked' : 'unlocked'}
          margin={[15, 15]}
        >
          <div key="description-widget">
            <DescriptionTable height={this.getWidgetHeight('description-widget')} />
          </div>
          {this.renderWidget('category-widget', CATEGORY, 'Categories')}
          {this.renderWidget('brand-widget', BRAND, 'Brands')}
          {this.renderWidget('merchant-widget', MERCHANT, 'Merchants')}
          {this.renderWidget('firstword-widget', DESCRIPTION_FIRST_WORD, 'First Words')}
          <div key="stats-widget">
            <DataSummary width={this.getWidgetWidth('stats-widget')} />
          </div>
        </ResponsiveReactGridLayout>
      </div>
    )
  }
}

export default WidgetWrapper
